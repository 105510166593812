@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  -webkit-font-smoothing: antialiased;
  font-family: 'Montserrat', sans-serif;
}

.faq-accordion > div.accordion__item:last-child > div > div {
  border: 0px;
}

.faq-accordion > div.accordion__item:last-child > div {
  border-bottom: 0px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.slick-slider {
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 52px auto 32px auto;
}

.footer-links li:last-child {
  display: none;
}

.section-title::before {
  content: url('https://greenmarbles-client-sites.imgix.net/mychargerinstall/ellipse_bg.webp');
  position: absolute;
  z-index: -10;
  left: -30px;
  top: -14px;
}

@media all and (max-width: 680px) {
  .section-title::before {
    left: -10px;
    top: -14px;
  }
}
